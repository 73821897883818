// @ts-ignore
import { Turbo } from "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import * as ActiveStorage from "@rails/activestorage"

import './alpine'
import "./controllers"

import "trix"

ActiveStorage.start()



// @ts-ignore
Turbo.setConfirmMethod((message, element) => {
  let modalType = element.getAttribute('data-modal-type') ?? 'default';
  const dialog = document.getElementById('turbo-confirm') as HTMLDialogElement;
  dialog.setAttribute('data-modal-type', modalType);
  dialog.querySelector('#modal-title')!.textContent = message
  dialog.showModal()

  return new Promise((resolve, reject) => {
    dialog.addEventListener('close', () => {
      resolve(dialog.returnValue === 'confirm')
    })
  })
})
